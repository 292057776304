
// import Video from './video/video.mp4';
import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";


const Services = () => {

    

  return (
    <>
    <NavBar/>


    <div class="serv-backg">
        <div class="row">
            <div class="col-md-7 mb-5">
                <div class="center">
                    {/* <h2 class="animated fadeIn" id="h2">About Us</h2>
                    <div class="divider"></div> */}
                 </div>
              </div>
              <div class="col-md-5">
                  <div class="skl-mbl-img" id="hide">
                      </div>
                </div>  
              </div>
       </div>


    <div className="container">
        <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              
            
         <div className="row pt-5 mb-5">
                <div className="col-md-12">
                    <h3 className="h3 mt-3" style={{textAlign: "center"}}>We Offer a variety of IT Solutions, here are our services:</h3>
                </div>
                <div className="row">

                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box1">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">DIGITAL SURVEILLANCE & SECURITY</h4>
                                            <p className="title-p">Amafel Digital Internet Protocol Surveillance solutions, is deployed at; Airports, Rail Terminals, Government buildings, Offices, Retail... </p>
                                             <Link  class="title_link" to="/Digital-Surveillance-Security">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>


                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box2">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">BIOMETRIC SOLUTION</h4>
                                            <p className="title-p">Door access is a critical function of all facilities, whether the purpose is security or safety the need to know who is trying to gain entry or exit from a facility is becoming...</p>
                                             <Link  class="title_link" to="/Biometric-Solution">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>

                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box3">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">STRUCTURED CABLING</h4>
                                            <p className="title-p">There was a phase, when having just a skeletal Network was enough, but today; the rapid explosion of traffic convergence (voice, video and data)...</p>
                                             <Link  class="title_link" to="/Structured-Cable">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>
            


                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box4">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">WIRELESS CONNECTIVITY</h4>
                                            <p className="title-p">Amafel Technologies offers a wide range of Broadband Wireless Communications solutions, from Public Wifi Hot Spot to Last mile Access....</p>
                                             <Link  class="title_link" to="Wireless-Connection">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>


                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box5">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">IT SUPPORT SERVICE</h4>
                                            <p className="title-p">We provide customized IT Support Solutions to fit your business and budget. Focus on what you do best, we will handle the rest...</p>
                                             <Link to='/Support' class="title_link" href="">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>
            

                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box6">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">WEB HOSTING, DESIGN AND DEVELOPMENT</h4>
                                            <p className="title-p">Amafel Technologies offers a wide range of enterprise and easy-to-use web solutions for small...</p>
                                             <Link  class="title_link" to="/Web-Hosting">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>
               

                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box7">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">NETWORKING</h4>
                                            <p className="title-p">We provide quality assistance within the field of data Communication covering a broad range to Technologies, from ad-hoc installations, internet services to full turnkey operations...</p>
                                             <Link  class="title_link" to="/Networking">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>

                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box8">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">IP PHONE SYSTEMS FOR YOUR BUSINESS</h4>
                                            <p className="title-p">Using VoIP (Voice over Internet Protocol) phone systems for your business is easier and more affordable than ever. VoIP systems have many....</p>
                                             <Link  class="title_link" to="/Ip-Phone-Systems">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>

                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box9">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">CYBER SECURITY</h4>
                                            <p className="title-p">Cybersecurity technology for your business has never been more important. IT security experts no longer warn you about if you’ll be attacked, but when. Threats against...</p>
                                             <Link  class="title_link" to="/Cyber-Security">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>



                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box10">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">MOBILE COMPUTER TRAINING</h4>
                                            <p className="title-p">Amafel Technologies, provides exciting quality trainings at the convenience of your business premises and home, we instruct at your pace...</p>
                                             <Link  class="title_link" to="/Mobile-Training">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>



                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box11">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">INTRUDER DETECTION SYSTEMS</h4>
                                            <p className="title-p">Our intrusion detection systems are sourced and designed for today’s security market ensuring that your organisation never becomes...</p>
                                             <Link  class="title_link" to="/Intruder-Detection-Systems">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>



                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box12">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">AUTOMATION SYSTEMS</h4>
                                            <p className="title-p">Automation is the future of Business Process Management (BPM) as businesses, small and big, streamline their operations by combining and managing...</p>
                                             <Link  class="title_link" to="/Automation-Service">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>


                </div>
            </div>

            </div>
            <div className="col-md-1"></div>
        </div>
    </div>








    
   
     
    <Footer/>




    
    </>
  )
}
export default Services;