
import React, {useEffect} from 'react'


const Footer = () => {
    return (
        <>
<a
        href="https://wa.me/2348029197938"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>

  <footer class="bg-white">
    <div class="container py-5">
      <div class="row py-4">
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h6 class="text-uppercase font-weight-bold mb-4">amafeltechnologies</h6>
          <ul class="list-unstyled mb-0">
            <li class="mb-2"><a href="/" class="text-muted">HOME</a></li>
            <li class="mb-2"><a href="/About-Us" class="text-muted">ABOUT US</a></li>
            <li class="mb-2"><a href="/Services" class="text-muted">SERVICES</a></li>
          </ul>
          
        </div>


        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h6 class="text-uppercase font-weight-bold mb-4">Services</h6>
          <ul class="list-unstyled mb-0">
            <li class="mb-2"><a href="/Services" class="text-muted">STRUCTURED CABLING</a></li>
            <li class="mb-2"><a href="/Services" class="text-muted">BIOMETRIC SOLUTION</a></li>
            <li class="mb-2"><a href="/Services" class="text-muted">CYBER SECURITY</a></li>
          </ul>
        </div>

        <div class="col-lg-4 col-md-6 mb-lg-0">
          <h6 class="text-uppercase font-weight-bold mb-4">Connect with us</h6>
          <ul class="list-inline mt-4">
            <li class="list-inline-item">
                <a href="#" target="_blank" title="twitter"><i class="fa fa-twitter f"></i></a></li>
            <li class="list-inline-item"><a href="#" target="_blank" title="facebook"><i class="fa fa-facebook f"></i></a></li>
            <li class="list-inline-item"><a href="#" target="_blank" title="instagram"><i class="fa fa-instagram f"></i></a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="bg-light py-4">
      <div class="container text-center">
                 <p class="text-muted mb-0 py-2">© 2021  Amafel Technologies  All rights reserved.</p>
      </div>
    </div>
  </footer>

        </>
    )
}
export default Footer;