
// import Video from './video/video.mp4';
import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";


const Support = () => {

    

  return (
    <>
    <NavBar/>


    <div class="support-backg">
        <div class="row">
            <div class="col-md-7 mb-5">
                <div class="center">
                    {/* <h2 class="animated fadeIn" id="h2">About Us</h2>
                    <div class="divider"></div> */}
                 </div>
              </div>
              <div class="col-md-5">
                  <div class="skl-mbl-img" id="hide">
                      </div>
                </div>  
              </div>
       </div>

       <div className="container">
        <div className="row pb-5">
            <div className="col-md-1 mt-4"></div>
            <div className="col-md-8">
                <div class="wrapper">
                <h1 className="h1 text-center">IT SUPPORT SERVICE</h1>
                <p style={{textAlign: "justify"}}>We provide customized IT Support Solutions to fit your business and budget. Focus on what you do best, we will handle the rest.</p>
                <p style={{textAlign: "justify"}}>At Amafel, we mind your business’s peculiar Information Technology needs and not having the right IT support in place, is mission critical.</p>
                <p style={{textAlign: "justify"}}>No matter the number of staff in your organization, be it 5, 500, and 1000 or more, we can set up an IT management strategy that is right for your organization, and we will find the most cost-effective way to get just the right level of support you need.</p>
                <p style={{textAlign: "justify"}}>Our team are certified professionals together with our local and international partners are our edge. Our responsiveness and professional competence with our friendliness, is what has continually given us the strength in our service deliveries.
                </p>
                </div>
            </div>
            <div className="col-md-4"></div>
        </div>
    </div>


    

    {/* <div className="contain" style={{width:'100%', background:'#fff'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                            
                    <div className="row pt-5 mb-5">
                            <div className="col-md-12">
                                <img class="d-block w-100" src="../img/partners.png" alt=""/>                   
                            </div>
                        </div>

                </div>
            </div>
        </div>
    </div> */}




     
    <Footer/>




    
    </>
  )
}
export default Support;