
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Support from "./pages/Support";
import Digital from "./pages/Digital-Surveillance";
import Biometric from "./pages/Biometric-Solution";
import Networking from "./pages/Networking";
import StructuredCable from "./pages/Structured-Cabling";
import WebHosting from "./pages/Web-Hosting";
import Wireless from "./pages/Wireless";
import Ip from "./pages/IP-Phone";
import CyberSecurity from "./pages/Cyber-Security";
import Intruder from './pages/intruder-System';
import MobileTraining from "./pages/Mobile-Training";
import Automation from "./pages/Automation";
import ContactUs from "./pages/Contact-us";



function App() {
  return (
    <BrowserRouter>
       <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/About-us" element={<About />}/>
          <Route path="/Services" element={<Services />}/>
          <Route path="/Support" element={<Support />}/>
          <Route path="/Digital-Surveillance-Security" element={<Digital />}/>
          <Route path="/Biometric-Solution" element={<Biometric />}/>
          <Route path="/Networking" element={<Networking />}/>
          <Route path="/Structured-Cable" element={<StructuredCable />}/>
          <Route path="/Web-Hosting" element={<WebHosting />}/>
          <Route path="/Wireless-Connection" element={<Wireless />}/>
          <Route path="/Ip-Phone-Systems" element={<Ip />}/>
          <Route path="/Cyber-Security" element={<CyberSecurity />}/>
          <Route path="/Intruder-Detection-Systems" element={<Intruder />}/>
          <Route path="/Mobile-Training" element={<MobileTraining />}/>
          <Route path="/Automation-Service" element={<Automation />}/>
          <Route path="/Contactus" element={<ContactUs />}/>
       </Routes>
    </BrowserRouter>
  );
}

export default App;
