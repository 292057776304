
// import Video from './video/video.mp4';
import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";


const About = () => {

    

  return (
    <>
    <NavBar/>
    <div class="abt-backg">
        <div class="row">
            <div class="col-md-7 mb-5">
                <div class="center">
                    {/* <h2 class="animated fadeIn" id="h2">About Us</h2>
                    <div class="divider"></div> */}
                 </div>
              </div>
              <div class="col-md-5">
                  <div class="skl-mbl-img" id="hide">
                      </div>
                </div>  
              </div>
       </div>

       <div className="container">
        <div className="row">
            <div className="col-md-1 mt-4"></div>
            <div className="col-md-12">
                <div class="wrapper">
                <h1 className="h1 text-center">About <span style={{fontWight: "600"}}>Amafel Technologies</span></h1>
                <p style={{textAlign: "justify"}}>AMAFEL Technologies profers ICT solutions that minds IT/ surveillance related business challenges and adds value to businesses. We take on one window source for end- to- end connectivity and ICT installations, focusing on Data, Voice, Video Surveillance as well as IT security solutions. We provide simple ICT solutions to highly complex problems thereby making possible for easy solution deployment by our customers.</p>
                <p style={{textAlign: "justify"}}>Our team are certified professionals together with our local and international partners are our edge. Our responsiveness and professional competence with our friendliness, is what has continually given us the strength in our service deliveries.
                </p>
                </div>
            </div>
            <div className="col-md-1"></div>
        </div>
    </div>
  
    <div className="container">
        <div className="row">
            <div className="col-md-1 mt-4"></div>
            <div className="col-md-12">
                <div class="wrapper">
                <h1 className="h1 text-center">Our <span style={{fontWight: "600"}}>Team</span></h1>
                <p style={{textAlign: "justify"}}>We’re looking for the best, brightest and most friendly people to be part of our amazing company. We believe in working hard, providing amazing service and living our passions. Every member of our company is appreciated and given the opportunity to flourish and grow. You will learn more in one year at Amafel than you will in ten anywhere else.</p>
                </div>
            </div>
            <div className="col-md-1"></div>
        </div>
    </div>  
    



    <div className="contain" style={{width:'100%', background:'#fff'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                            
                    <div className="row pt-5 mb-5">
                            <div className="col-md-12">
                                <img class="d-block w-100" src="../img/partners.png" alt=""/>                   
                            </div>
                        </div>

                </div>
            </div>
        </div>
    </div>







    
   
     
    <Footer/>




    
    </>
  )
}
export default About;