
// import Video from './video/video.mp4';
import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";


const Digital = () => {

    

  return (
    <>
    <NavBar/>


    <div class="support-backg">
        <div class="row">
            <div class="col-md-7 mb-5">
                <div class="center">
                    {/* <h2 class="animated fadeIn" id="h2">About Us</h2>
                    <div class="divider"></div> */}
                 </div>
              </div>
              <div class="col-md-5">
                  <div class="skl-mbl-img" id="hide">
                      </div>
                </div>  
              </div>
       </div>

       <div className="container">
        <div className="row pb-5">
            <div className="col-md-1 mt-4"></div>
            <div className="col-md-8">
                <div class="wrapper">
                <h1 className="h1 text-center">DIGITAL SURVEILLANCE & SECURITY</h1>
                <p style={{textAlign: "justify"}}>Amafel Digital Internet Protocol Surveillance solutions, is deployed at; Airports, Rail Terminals, Government buildings, Offices, Retail sites, Financial Institutions, Parking garages, Hotels, Restaurants and Homes etc. Security concerns have become a thing of the past but our solution is sophisticated and IP (Internet Protocol) based. You will be able to monitor without boundaries and keep record of activities (video and audio) as you may desire.</p>

                <p style={{textAlign: "justify"}}>Our team will listen to your needs, study your environment to customize the right video surveillance package you need, in line with the most modern surveillance industry standards. CCTV, Electric Fencing, Alarm Systems, Panic Alert system, Access Control and walkthrough etc, are the best ways to protect your environment.</p>
                </div>
            </div>
            <div className="col-md-4"></div>
        </div>
    </div>


    

    {/* <div className="contain" style={{width:'100%', background:'#fff'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                            
                    <div className="row pt-5 mb-5">
                            <div className="col-md-12">
                                <img class="d-block w-100" src="../img/partners.png" alt=""/>                   
                            </div>
                        </div>

                </div>
            </div>
        </div>
    </div> */}




     
    <Footer/>




    
    </>
  )
}
export default Digital;