
// import Video from './video/video.mp4';
import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";


const ContactUs = () => {

    

  return (
    <>
    <NavBar/>

{/* 
    <div class="support-backg">
        <div class="row">
            <div class="col-md-7 mb-5">
                <div class="center">
                    <h2 class="animated fadeIn" id="h2">About Us</h2>
                    <div class="divider"></div>
                 </div>
              </div>
              <div class="col-md-5">
                  <div class="skl-mbl-img" id="hide">
                      </div>
                </div>  
              </div>
       </div> */}

       <div className="container">
        <div className="row pb-5">
            <div className="col-md-1 mt-4"></div>
            <div className="col-md-10">
                <div class="wrapper">
                <h1 className="h1 text-center">CONTACT US</h1>
                <div class="row mt-2 mn-4">
                   <div class="col-md-12"> 
                       
                       <form action="" method="post" enctype="multipart/form-data" class="row">
                           
                           <div class="col-md-6">
                               <div class="row">
                                   <div class="col-md-12">                                      

                                        <div class="md-form mb-5 text-center">

                                            <p style={{color:'#4f4f4f', fontWeight: "bold"}}>Port Harcourt Address:</p>
                                            <p>314 NTA Road opposite NTA Station Mgbuoba Port Harcourt.</p>
                                            <p class="mt-4" style={{color:'#4f4f4f', fontWeight: "bold"}}>Lagos Address:</p>
                                            <p>5b Medical Road Ikeja Lagos</p>
                                            <p>Telephone: +2348029197938</p>
                                            <p>Mobile: +2348033502921</p>
                                            <p>Email:  info@amafeltechnologies.com</p>
                                        </div>
                                       
                                   </div>
                               </div>
                           </div>
                           
                           <div class="col-md-6"> 
                           
                               <h4 class="text-center">Write Us</h4>
                                <div class="mb-4">
                                  <input type="text" id="form34" class="form-control validate" placeholder="Full Name" name="name" required=""/>
                                </div>

                                <div class="mb-4">
                                  <input type="email" id="form29" class="form-control validate" placeholder="Email Address" name="email" required=""/>
                                 </div>

                                <div class="mb-4">
                                  <input type="tel" id="form58" class="form-control validate" placeholder="Phone Number" name="phone" required=""/>
                                </div>


                                <div class="mb-4">
                                  <textarea type="text" id="form8" class="md-textarea form-control" rows="4" name="msg" placeholder="Your message"></textarea>
                                </div>
                               
                                <button class="btn btn-warn btn-block btn-sm waves-effect waves-light" name="submit">Submit</button>
                           
                           </div>
                       
                       </form>
                       
                    </div>
                </div>
                
                </div>
            </div>
            <div className="col-md-1"></div>
        </div>
    </div>


    

    {/* <div className="contain" style={{width:'100%', background:'#fff'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                            
                    <div className="row pt-5 mb-5">
                            <div className="col-md-12">
                                <img class="d-block w-100" src="../img/partners.png" alt=""/>                   
                            </div>
                        </div>

                </div>
            </div>
        </div>
    </div> */}




     
    <Footer/>




    
    </>
  )
}
export default ContactUs;