
// import Video from './video/video.mp4';
import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";


const Ip = () => {

    

  return (
    <>
    <NavBar/>


    <div class="support-backg">
        <div class="row">
            <div class="col-md-7 mb-5">
                <div class="center">
                    {/* <h2 class="animated fadeIn" id="h2">About Us</h2>
                    <div class="divider"></div> */}
                 </div>
              </div>
              <div class="col-md-5">
                  <div class="skl-mbl-img" id="hide">
                      </div>
                </div>  
              </div>
       </div>

       <div className="container">
        <div className="row pb-5">
            <div className="col-md-1 mt-4"></div>
            <div className="col-md-8">
                <div class="wrapper">
                <h1 className="h1 text-center">IP PHONE SYSTEMS FOR YOUR BUSINESS </h1>
                <p style={{textAlign: "justify"}}>Using VoIP (Voice over Internet Protocol) phone systems for your business is easier and more affordable than ever. VoIP systems have many advantages over traditional phone systems. Using VoIP, phones in multiple locations function as part of the same system-whether they’re next door, across town, or across the world-phones can be answered, attended and transferred.</p>
                <p style={{textAlign: "justify"}}>Amafel Hosted VoIP makes all the features of an enterprise-grade telephone system accessible and affordable for your business. Check out some of the other great features of a VoIP system:</p>
                <ul> 
                    <li style="color:#666!important;">Unlimited long distance</li>
                    <li style="color:#666!important;">Multi-site support</li>
                    <li style="color:#666!important;">Use your own bandwidth model</li>
                    <li style="color:#666!important;">Find me, follow me</li>
                    <li style="color:#666!important;">Voicemail to email</li>
                    <li style="color:#666!important;">Custom greetings and routing</li>
                    <li style="color:#666!important;">Call recording and conferencing</li>
                    <li style="color:#666!important;">Unlimited area codes</li>
                    </ul>
                    <p style={{textAlign: "justify"}}>With Amafel, your business will receive the best hardware and software to run your VOIP systems AND get the world-class service of our personal consultants.</p>

                </div>
            </div>
            <div className="col-md-4"></div>
        </div>
    </div>


    

    {/* <div className="contain" style={{width:'100%', background:'#fff'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                            
                    <div className="row pt-5 mb-5">
                            <div className="col-md-12">
                                <img class="d-block w-100" src="../img/partners.png" alt=""/>                   
                            </div>
                        </div>

                </div>
            </div>
        </div>
    </div> */}




     
    <Footer/>




    
    </>
  )
}
export default Ip;