import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";

const NavBar = () => {
  return (
    <>
    
    <div class="top-nav"> 

        <div class="top-left">
            <ul class="nav">
                <li class="nav-item">
                    <a href="https://facebook.com/amafeltechnologies" class="social1"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                </li>
            <li class="nav-item">
                    <a href="https://twitter.com/amafeltechnologies" class="social2"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                </li>
            <li class="nav-item">
                    <a href="#" class="social2"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                </li>
            </ul>
        </div>

        <div class="top-right">
           <ul class="nav">
            <li class="nav-item">
                    <a href="tel:+2348029197938" style={{color:"#fff", paddingRight:"8px", fontSize:"12px",  fontWeight: '500'}}><i class="fa fa-phone" aria-hidden="true"></i> +234 8029197938</a>
                </li>
            <li class="nav-item" id="hide">
                    <a href="mailto:info@amafeltechnologies.com" style={{color:"#fff", paddingRight:"50px", fontSize:"12px",  fontWeight: '500'}}><i class="fa fa-envelope" aria-hidden="true"></i> info@amafeltechnologies.com</a>
                </li>
            </ul>
         </div>

     </div>



    <nav class="navbar navbar-expand-md navbar-light" id="navbarNav1">
      <div class="container">
          
           <a class="navbar-brand" href="./" id="logo-m"> 
               <img src="img/LOGO.jpg" width="50" style={{float:'left'}} class="d-inline-block align-top" alt=""/>     
            </a>


          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicExampleNav" aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>


          <div class="collapse navbar-collapse" id="basicExampleNav">


            <ul class="navbar-nav ml-auto">
                  <li class="nav-item">
                    <Link class="nav-link waves-effect waves-light active-nav" to="/">HOME <span class="sr-only">(current)</span></Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link waves-effect waves-light" to="/About-us">ABOUT US</Link>
                  </li>
                  <li class="nav-item dropdown">
                    <Link class="nav-link  waves-effect waves-light" to="/Services">
                    SERVICES 
                    </Link>
                    </li>
                  <li class="nav-item">
                    <Link to="/Support" class="nav-link waves-effect waves-light">IT SUPPORT</Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link waves-effect waves-light" to="/Contactus">CONTACT US</Link>
                  </li>
            </ul>
            



          </div>
          
         </div>
    </nav>





    </>
  )
}
export default NavBar;