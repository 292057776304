
// import Video from './video/video.mp4';
import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";


const CyberSecurity = () => {

    

  return (
    <>
    <NavBar/>


    <div class="support-backg">
        <div class="row">
            <div class="col-md-7 mb-5">
                <div class="center">
                    {/* <h2 class="animated fadeIn" id="h2">About Us</h2>
                    <div class="divider"></div> */}
                 </div>
              </div>
              <div class="col-md-5">
                  <div class="skl-mbl-img" id="hide">
                      </div>
                </div>  
              </div>
       </div>

       <div className="container">
        <div className="row pb-5">
            <div className="col-md-1 mt-4"></div>
            <div className="col-md-8">
                <div class="wrapper">
                <h1 className="h1 text-center">CYBER SECURITY </h1>
                <p style={{textAlign: "justify"}}>Cybersecurity technology for your business has never been more important. IT security experts no longer warn you about if you’ll be attacked, but when. Threats against your data come from more sources than ever. Small businesses are most vulnerable to digital threats and often the least prepared.</p>
                <p style={{textAlign: "justify"}}>Amafel team of digital security consultants have the expertise and training to help your business navigate the complexities of cybersecurity. Securing your business data is our priority, and we bring the tools, the people and the processes needed to keep your business secure from any cyber threat.</p>

                </div>
            </div>
            <div className="col-md-4"></div>
        </div>
    </div>


    

    {/* <div className="contain" style={{width:'100%', background:'#fff'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                            
                    <div className="row pt-5 mb-5">
                            <div className="col-md-12">
                                <img class="d-block w-100" src="../img/partners.png" alt=""/>                   
                            </div>
                        </div>

                </div>
            </div>
        </div>
    </div> */}




     
    <Footer/>




    
    </>
  )
}
export default CyberSecurity;