
// import Video from './video/video.mp4';
import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";


const Home = () => {

    

  return (
    <>
    <NavBar/>


    <div class="contain">
                <div class="row">
                    <div class="col-md-12 mb-5" >
                        <div id="carouselExampleControls" class="carousel slide card" data-ride="carousel">
                              <div class="carousel-inner">
                                <div class="carousel-item active">
                                  <img class="d-block w-100" src="../img/CCTVCalendar.jpg" alt="First slide"/>
                                </div>
                                <div class="carousel-item">
                                  <img class="d-block w-100" src="../img/CCTVCalendar123.jpg" alt="Second slide"/>
                                </div>
                                <div class="carousel-item">
                                  <img class="d-block w-100" src="../img/CCTVCalendar12.jpg" alt="Third slide"/>
                                </div>
                                <div class="carousel-item">
                                  <img class="d-block w-100" src="../img/CCTVCalendar5.jpg" alt="fOURTH slide"/>
                                </div>
                              </div>
                              <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                              </a>
                              <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                              </a>
                         </div>
                        
                                             
                    </div>
                    
                </div>

    </div>

    <div className="container">
        <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
                <div class="wpb_wrapper">
                <h1 className="h1 text-center">We are a <span style={{fontWight: "600"}}>Professional IT Company</span></h1>
                <p style={{textAlign: "justify"}}>Amafel Technologies is a registered Information and Communication Technology   Company with a long working profile in Nigeria. We are a solution provider brand for all ICT and Surveillance needs. We canopy the following areas such as; Sales, Marketing, Importation, Exportation, Distribution, Supplies, Installation and Maintenance of all kinds of Surveillance System and Information and Communication Technology Equipment. Our mandate is to deliver ICT and Security solutions that are highly cost effective and cost efficient to the fulfillment and satisfaction of our clients.</p>
                <p style={{textAlign: "justify"}}>Amafel Technologies is project oriented and client-driven. The Company’s culture, encourages teamwork and innovation. With all these attributes and resources, the company is well placed to compete and succeed in the local as well as international contracting arena while being recognized as a quality service provider.
                </p>
                </div>
            </div>
            <div className="col-md-2"></div>
        </div>
    </div>


    <div className="container">
        <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              
            
         <div className="row pt-5 mb-5">
                <div className="col-md-12">
                    <h3 className="h3 mt-3" style={{textAlign: "center"}}>We Offer a variety of IT Solutions, here are few of the most popular services:</h3>
                </div>
                <div className="row">

                <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box1">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">DIGITAL SURVEILLANCE & SECURITY</h4>
                                            <p className="title-p">Amafel Digital Internet Protocol Surveillance solutions, is deployed at; Airports, Rail Terminals, Government buildings, Offices, Retail... </p>
                                             <Link  class="title_link" to="/Digital-Surveillance-Security">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>


                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box2">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">BIOMETRIC SOLUTION</h4>
                                            <p className="title-p">Door access is a critical function of all facilities, whether the purpose is security or safety the need to know who is trying to gain entry or exit from a facility is becoming...</p>
                                             <Link  class="title_link" to="/Biometric-Solution">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>


                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box6">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">WEB HOSTING, DESIGN AND DEVELOPMENT</h4>
                                            <p className="title-p">Amafel Technologies offers a wide range of enterprise and easy-to-use web solutions for small...</p>
                                             <Link  class="title_link" to="/Web-Hosting">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>
               

                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box7">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">NETWORKING</h4>
                                            <p className="title-p">We provide quality assistance within the field of data Communication covering a broad range to Technologies, from ad-hoc installations, internet services to full turnkey operations...</p>
                                             <Link  class="title_link" to="/Networking">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>


                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box9">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">CYBER SECURITY</h4>
                                            <p className="title-p">Cybersecurity technology for your business has never been more important. IT security experts no longer warn you about if you’ll be attacked, but when. Threats against...</p>
                                             <Link  class="title_link" to="/Cyber-Security">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>


                    <div className="col-md-4 mt-3">
                            <div className="row srollContainer">
                                    <div className="col-1"></div> 
                                <div className="col-10">  
                                    <div className="row">
                                        <div className="col-12" id="box3">
                                        </div>
                                        <div className="col-12" style={{backgroundColor:"#fff"}}>
                                            <div class="row p-2 bottom-half">
                                            <h4 className="title-text">STRUCTURED CABLING</h4>
                                            <p className="title-p">There was a phase, when having just a skeletal Network was enough, but today; the rapid explosion of traffic convergence (voice, video and data)...</p>
                                             <Link  class="title_link" to="/Structured-Cable">Read More</Link>
                                            </div>
                                        </div>
                                    </div>                     
                                </div>
                                    <div className="col-1"></div> 
                            </div>
                    </div>


                </div>
            </div>

            </div>
            <div className="col-md-1"></div>
        </div>
    </div>




    <div className="contain" style={{width:'100%', background:'#fff'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                            
                    <div className="row pt-5 mb-5">
                            <div className="col-md-12">
                                <img class="d-block w-100" src="../img/partners.png" alt=""/>                   
                            </div>
                        </div>

                </div>
            </div>
        </div>
    </div>


    
   
     
    <Footer/>




    
    </>
  )
}
export default Home;