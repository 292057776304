
// import Video from './video/video.mp4';
import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";


const Intruder = () => {

    

  return (
    <>
    <NavBar/>


    <div class="support-backg">
        <div class="row">
            <div class="col-md-7 mb-5">
                <div class="center">
                    {/* <h2 class="animated fadeIn" id="h2">About Us</h2>
                    <div class="divider"></div> */}
                 </div>
              </div>
              <div class="col-md-5">
                  <div class="skl-mbl-img" id="hide">
                      </div>
                </div>  
              </div>
       </div>

       <div className="container">
        <div className="row pb-5">
            <div className="col-md-1 mt-4"></div>
            <div className="col-md-8">
                <div class="wrapper">
                <h1 className="h1 text-center">INTRUDER DETECTION SYSTEMS </h1>
                <p style={{textAlign: "justify"}}>Our intrusion detection systems are sourced and designed for today’s security market ensuring that your organisation never becomes compromised.</p>
                <p style={{textAlign: "justify"}}>Different types of our intrusion detection systems?</p>
                <p style={{textAlign: "justify"}}>Dual Technology – These are prefect for the protection of rooms and offices, school corridors, warehouse areas, retail environments or almost any environment you can think of. They come with anti-masking detection built in (applicable on grade 3 systems), meaning that the area in front of the sensor is continuously monitored. Anti-masking can occur when an intruder attempts to block the sensor by vandalising it or placing items in front of the unit to attempt to override the system and go unnoticed. Any form of obstruction placed in front of the unit will activate the anti-masking process and inform you or the monitoring station.</p>
                <p style={{textAlign: "justify"}}>Door Contacts – Magnetic contact detection devices are used widely in alarm systems controlling whether a door is open or closed at any particular time. The contacts communicate to the alarm system and inform the end user when setting the system if the door is closed or open.</p>
                <p style={{textAlign: "justify"}}>Vibration Sensors – Vibration Sensors are designed to protect high-value and high-risk assets in financial, retail and other organisations. The vibration sensors can be installed on to new or existing security systems and offer a higher level of protection and false alarm immunity than traditional shock sensors, which often lack the ability to discriminate between vibration and real attacks</p>
                <p style={{textAlign: "justify"}}>Panic Buttons – Our current panic buttons meet the new and current standards for requesting Police attendance in an emergency situation. Panic buttons are key to those who operate in retail, bank or any environments that deals with the public in a face to face environment. Should a threat occur a member of staff can press the panic button without alerting the intruder. The alarm is then sent silently to the monitoring station and the Police will be notified.
                </p>

                </div>
            </div>
            <div className="col-md-4"></div>
        </div>
    </div>


    

    {/* <div className="contain" style={{width:'100%', background:'#fff'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                            
                    <div className="row pt-5 mb-5">
                            <div className="col-md-12">
                                <img class="d-block w-100" src="../img/partners.png" alt=""/>                   
                            </div>
                        </div>

                </div>
            </div>
        </div>
    </div> */}




     
    <Footer/>




    
    </>
  )
}
export default Intruder;