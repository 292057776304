
// import Video from './video/video.mp4';
import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";


const WebHosting = () => {

    

  return (
    <>
    <NavBar/>


    <div class="support-backg">
        <div class="row">
            <div class="col-md-7 mb-5">
                <div class="center">
                    {/* <h2 class="animated fadeIn" id="h2">About Us</h2>
                    <div class="divider"></div> */}
                 </div>
              </div>
              <div class="col-md-5">
                  <div class="skl-mbl-img" id="hide">
                      </div>
                </div>  
              </div>
       </div>

       <div className="container">
        <div className="row pb-5">
            <div className="col-md-1 mt-4"></div>
            <div className="col-md-8">
                <div class="wrapper">
                <h1 className="h1 text-center">WEB HOSTING DESIGN AND DEVELOPMENT</h1>
                <p style={{textAlign: "justify"}}>Web Development</p>
                <p style={{textAlign: "justify"}}>Amafel Technologies offers a wide range of enterprise and easy-to-use web solutions for small, medium-size business and Large-Scale Companies. Our web solutions ranges from expertly designed and rich website to communicate with your clients and the general public, intranet knowledge base system for employees or a multi-level extranet for your partners and the next generation web based enterprise solutions that includes rich e-commerce solutions, web applications and portal development solutions etc.</p>

                </div>
            </div>
            <div className="col-md-4"></div>
        </div>
    </div>


    

    {/* <div className="contain" style={{width:'100%', background:'#fff'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                            
                    <div className="row pt-5 mb-5">
                            <div className="col-md-12">
                                <img class="d-block w-100" src="../img/partners.png" alt=""/>                   
                            </div>
                        </div>

                </div>
            </div>
        </div>
    </div> */}




     
    <Footer/>




    
    </>
  )
}
export default WebHosting;